<template>
  <v-layout row justify-center>
      <v-dialog v-model="dialog" scrollable persistent max-width="1180px">
        <v-card ref="formCard">
        <v-card-title>
          <span class="headline ma-5" v-if="formType === 'UPDATE'">Update Merchant</span>
          <span class="headline ma-5" v-else>Create New Merchant</span>
          <v-card-text>
              <v-stepper v-if="hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')" v-model="e1" class="mx-5 elevation-0">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1"> General/Processing Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 2" :step="dynamicStepMDR"> Merchant Discount Rate </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 3" :step="dynamicStepOD"> Online Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 4" :step="dynamicSteDBD"> Depository Bank Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 5" :step="dynamicStepCDF"> Contact Details/Fees </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
               <v-stepper v-else v-model="e1" class="mx-5 elevation-0">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1"> General/Processing Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 2" :step="dynamicStepOD"> Online Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 3" :step="dynamicSteDBD"> Depository Bank Details </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="e1 > 4" :step="dynamicStepCDF"> Contact Details/Fees </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
          </v-card-text>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-stepper v-model="e1" class="elevation-0">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formEntryOne">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Name*"
                            clearable
                            hide-details="auto"
                            data-cy="form-name"
                            v-model="form.name"
                            required
                            :rules="[rules.requiredField]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Merchant Address"
                            clearable
                            hide-details="auto"
                            v-model="form.merchantAddress"
                            data-cy="form-merchant-address"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Legal Name"
                            clearable
                            hide-details="auto"
                            v-model="form.legalName"
                            data-cy="form-legal-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Legal Form"
                            clearable
                            hide-details="auto"
                            v-model="form.legalForm"
                            data-cy="form-legal-form"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Trade Name"
                            clearable
                            hide-details="auto"
                            v-model="form.tradeName"
                            data-cy="form-trade-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Company Name"
                            clearable
                            hide-details="auto"
                            v-model="form.companyName"
                            data-cy="form-company-name"
                          ></v-text-field>
                        </v-flex>
                         <v-flex xs12 sm8>
                          <v-text-field
                            label="Client Number"
                            clearable
                            hide-details="auto"
                            v-model="form.clientNumber"
                            data-cy="form-client-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="TIN"
                            clearable
                            hide-details="auto"
                            v-model="form.tinNumber"
                            data-cy="form-tin-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="isNotPaymentAgent">
                          <v-autocomplete
                           clearable chips
                           :items="paymentAgentList" 
                           item-text="name"
                           item-value="code" 
                           label="Payment Agent*" 
                           required :rules="[rules.requiredSelection]"
                           v-model="form.paymentAgent" 
                           data-cy="form-payment-agent"
                           return-object>
                          </v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Postal Code"
                            clearable
                            hide-details="auto"
                            v-model="form.postalCode"
                            data-cy="form-postal-code"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="City*"
                            clearable
                            hide-details="auto"
                            v-model="form.city"
                            data-cy="form-city"
                            required
                            :rules="[rules.requiredField]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="State"
                            clearable
                            hide-details="auto"
                            v-model="form.state"
                            data-cy="form-state"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Province"
                            clearable
                            hide-details="auto"
                            v-model="form.province"
                            data-cy="form-province"
                          ></v-text-field>
                        </v-flex>
                        <v-flex  xs12 sm8>
                          <v-autocomplete
                            v-model="form.country"
                            :items="countryList"
                            item-text="name"
                            item-value="numCode"
                            label="Country*"
                            required :rules="[rules.requiredSelection]"
                            data-cy="form-country-list"
                            clearable
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Comments"
                            clearable
                            hide-details="auto"
                            v-model="form.comments"
                            data-cy="form-comments"
                          ></v-text-field>
                        </v-flex>
                        <v-flex  xs12 sm8>
                          <v-autocomplete
                            v-model="form.merchantCategoryCode"
                            :items="merchantCategoryCodeList"
                            item-text="code"
                            item-value="description"
                            label="Merchant Category Code*"
                            required :rules="[rules.requiredSelection]"
                            data-cy="form-merchant-category-code"
                            clearable
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-switch
                            v-model="form.enabled"
                            :label="`Merchant Enabled: ${form.enabled ? form.enabled.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Minimum Amount"
                            @keypress="onlyForCurrency"
                            v-model="form.minimumAmount"
                            data-cy="form-minimum-amount"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Maximum Amount"
                            @keypress="onlyForCurrency"
                            v-model="form.maximumAmount"
                            data-cy="form-maximum-amount"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Average Amount"
                            @keypress="onlyForCurrency"
                            v-model="form.averageAmount"
                            data-cy="form-average-amount"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Hold Out Rate"
                            @keypress="onlyForCurrency"
                            v-model="form.holdOutRate"
                            data-cy="form-hold-out-rate"
                          ></v-text-field>
                        </v-flex>
                        <!-- <v-flex xs12 sm8>
                          <v-switch
                            v-model="form.passDiscountRate"
                            :label="`Pass Discount Rate: ${form.passDiscountRate ? form.passDiscountRate.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Discount Rate"
                            @keypress="onlyForCurrency"
                            v-model="form.discountRate"
                            data-cy="form-discount-rate"
                          ></v-text-field>
                        </v-flex> -->
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Service Fee Label"
                            clearable
                            hide-details="auto"
                            v-model="form.serviceFeeLabel"
                            data-cy="form-service-fee-label"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Purchase Identifier Format"
                            clearable
                            hide-details="auto"
                            v-model="form.purchaseIdentifierFormat"
                            data-cy="form-purchase-idenitifer-format"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.isAsymmetricKeyEnabled"
                            :label="`is Asymmetric Enabled(?): ${form.isAsymmetricKeyEnabled ? form.isAsymmetricKeyEnabled.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <div class="text-right stepper-1-btns">
                      <v-btn color="primary" @click="next(e1++)">Continue</v-btn>
                    </div>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="dynamicStepMDR" >
                  <v-form ref="formEntryTwo">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-cols cols="12" md="3" lg :style="{ marginTop: '20px', marginRight: '20px' }">
                              <span class="headline">Bancnet Merchant Discount Rate</span>
                          </v-cols>
                          <v-radio-group v-model="bancnetRadioGroupVal">
                            <v-layout row wrap align-center>
                              <v-row no-gutters>
                                <v-radio class ="ma-5" label="Percentage" value="percentageBancnetVal" />
                                <v-col cols="4" md="4">
                                  <v-text-field
                                    append-icon="mdi-percent"
                                    type="number"
                                    v-model="form.discountRateDebit"
                                    :rules="[amountRule]"
                                    :value="form.discountRateDebit"
                                    :disabled="bancnetRadioGroupVal === 'fixedFeeBancnetVal'"
                                  />
                                </v-col>
                              </v-row>
                            </v-layout>  
                            <v-layout row wrap align-center>
                              <v-row no-gutters>    
                                <v-radio class ="ma-5" label="Fixed Fee" value="fixedFeeBancnetVal" />
                                <v-col cols="4" md="4">
                                  <v-text-field
                                    type="number"
                                    v-model="form.fixedDiscountRateDebit"
                                    :rules="[amountRule]"
                                    :value="form.fixedDiscountRateDebit"
                                    :disabled="bancnetRadioGroupVal === 'percentageBancnetVal'"
                                  />
                                </v-col>
                              </v-row>
                            </v-layout>
                          </v-radio-group>

                          <v-cols cols="12" md="3" lg :style="{ marginTop: '20px', marginRight: '20px' }">
                              <span class="headline">Visa Merchant Discount Rate</span>
                          </v-cols>
                          <v-radio-group v-model="visaRadioGroupVal">
                            <v-layout row wrap align-center>
                              <v-row no-gutters>
                                <v-radio class ="ma-5" label="Percentage" value="percentageVisaVal" />
                                <v-col cols="4" md="4">
                                  <v-text-field
                                    type="number"
                                    append-icon="mdi-percent"
                                    v-model="form.discountRate"
                                    :value="form.discountRate"
                                    :rules="[amountRule]"
                                    :disabled="visaRadioGroupVal === 'fixedFeeVisaVal'"
                                  />
                                </v-col>
                              </v-row>
                            </v-layout>  
                            <v-layout row wrap align-center>
                              <v-row no-gutters>    
                                <v-radio class ="ma-5" label="Fixed Fee" value="fixedFeeVisaVal" />
                                <v-col cols="4" md="4">
                                  <v-text-field
                                    type="number"
                                    v-model="form.fixedDiscountRate"
                                    :value="form.fixedDiscountRate"
                                    :rules="[amountRule]"
                                    :disabled="visaRadioGroupVal === 'percentageVisaVal'"
                                  />
                                </v-col>
                              </v-row>
                            </v-layout>
                          </v-radio-group>
                          
                          <v-cols cols="12" md="3" lg :style="{ marginTop: '20px', marginRight: '20px' }">
                              <span class="headline">Distribution Fees</span>
                          </v-cols>
                            <v-layout row wrap align-center>
                              <v-row no-gutters>
                                <v-text class ="ma-5" :style="{ marginTop: '20px', marginRight: '20px' }"> Merchant: </v-text>
                                <v-col cols="4" md="4">
                                  <v-text-field  type="number" v-model="field1" />
                                </v-col>
                              </v-row>
                            </v-layout>  
                            <v-layout row wrap align-center>
                              <v-row no-gutters>    
                                <v-text class ="ma-5" :style="{ marginTop: '20px', marginRight: '20px' }"> Branch: </v-text>
                                <v-col cols="4" md="4">
                                  <v-text-field type="number" :value="100 - field1" disabled />
                                </v-col>
                              </v-row>
                            </v-layout>
                            <v-layout row wrap align-center>
                              <v-row no-gutters>    
                                 <v-col cols="4" md="4">
                                  <v-switch
                                    v-model="form.passFlatFee"
                                    :label="`Pass Cash Out Fee: ${form.passFlatFee ? form.passFlatFee.toString() : 'false'}`"
                                  ></v-switch>
                                 </v-col>
                              </v-row>
                            </v-layout>
                            <v-layout row wrap align-center>
                              <v-row no-gutters>
                                 <v-col cols="4" md="4">
                                  <v-text-field
                                    label="Bancnet Cash Out Fee"
                                    @keypress="onlyForCurrency"
                                    v-model="form.bancnetFlatFee"
                                    data-cy="form-bancnet-flat-fee"
                                  ></v-text-field>
                                 </v-col>
                              </v-row>
                            </v-layout>
                             <v-layout row wrap align-center>
                              <v-row no-gutters>
                                 <v-col cols="4" md="4">
                                  <v-text-field
                                    label="Withholding Tax Rate"
                                    @keypress="onlyForCurrency"
                                    v-model="form.withholdingTaxRate"
                                    data-cy="form-with-holding-tax-rate"
                                  ></v-text-field>
                                 </v-col>
                              </v-row>
                             </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn class="mr-2" @click="back(e1--)" color="warning">Back</v-btn>
                      <v-btn color="primary" @click="next(e1++)">Continue</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="dynamicStepOD">
                  <v-form ref="formEntryThree">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Merchant Id*"
                            clearable
                            hide-details="auto"
                            v-model="form.merchantId"
                            required
                            :rules="[rules.requiredField, rules.merchantCodeFormat]"
                           data-cy="form-merchant-id"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Merchant Code*"
                            clearable
                            hide-details="auto"
                            v-model="form.merchantCode"
                            required
                            :rules="[rules.requiredField, rules.merchantCodeFormat]"
                           data-cy="form-merchant-code"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Expire After"
                            clearable
                            hide-details="auto"
                            v-model="form.expireAfter"
                            :rules="[rules.positiveIntegers]"
                            data-cy="form-expire-after"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.validatePayconnect"
                            :label="`Validate Payconnect: ${form.validatePayconnect ? form.validatePayconnect.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.stip"
                            :label="`STIP: ${form.stip ? form.stip.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.amountEditable"
                            :label="`Amount Editable: ${form.amountEditable ? form.amountEditable.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Merchant Logo URL"
                            clearable
                            hide-details="auto"
                            v-model="form.merchantLogoUrl"
                            data-cy="form-merchnat-logo-url"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.isMultipleBiller"
                            :label="`Is Multiple Biller: ${form.isMultipleBiller ? form.isMultipleBiller.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')"> 
                          <v-switch
                            v-model="form.allowWalletPayment"
                            :label="`Allow Wallet Payment: ${form.allowWalletPayment ? form.allowWalletPayment.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.isMobileNumberRequired"
                            :label="`Is Mobile Number Required: ${form.isMobileNumberRequired ? form.isMobileNumberRequired.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.hasPriceAdjustment"
                            :label="`Has Price Adjustment: ${form.hasPriceAdjustment ? form.hasPriceAdjustment.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Endpoint URL"
                            clearable
                            hide-details="auto"
                            v-model="form.endpointUrl"
                            data-cy="form-end-point-url"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Connect Timeout"
                            clearable
                            hide-details="auto"
                            v-model="form.connectTimeout"
                            :rules="[rules.positiveIntegers]"
                            data-cy="form-connect-timeout"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Read Timeout"
                            clearable
                            hide-details="auto"
                            v-model="form.readTimeout"
                            :rules="[rules.positiveIntegers]"
                            data-cy="form-read-timeout"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.randomizeRefNum"
                            :label="`Randomized Reference Number: ${form.randomizeRefNum ? form.randomizeRefNum.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-switch
                            v-model="form.referenceReusable"
                            :label="`Reusable Reference Number: ${form.referenceReusable ? form.referenceReusable.toString() : 'false'}`"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Account Code"
                            clearable
                            hide-details="auto"
                            v-model="form.accountCode"
                            data-cy="form-account-code"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Branch Code"
                            clearable
                            hide-details="auto"
                            v-model="form.branchCode"
                            data-cy="form-branch-code"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')" >
                          <v-text-field
                            label="Account Username"
                            clearable
                            hide-details="auto"
                            v-model="form.accountUsername"
                            data-cy="form-account-user-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Account Usercode"
                            clearable
                            hide-details="auto"
                            v-model="form.accountUsercode"
                            data-cy="form-account-user-code"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Account Password"
                            clearable
                            hide-details="auto"
                            v-model="form.accountPassword"
                            data-cy="form-account-password"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn class="mr-2" @click="back(e1--)" color="warning">Back</v-btn>
                      <v-btn color="primary" @click="next(e1++)">Continue</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="dynamicSteDBD">
                  <v-form ref="formEntryFour">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Bank Name"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankName"
                            data-cy="form-depository-bank-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Bank Account Name"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankAccountName"
                            data-cy="form-depository-bank-account-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Bank Account"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankAccount"
                            data-cy="form-depository-bank-account"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Bank Name USD"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankNameUSD"
                            data-cy="form-depository-bank-name-usd"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Bank Account Name USD"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankAccountNameUSD"
                            data-cy="form-depository-bank-account-name-usd"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Bank Account USD"
                            clearable
                            hide-details="auto"
                            v-model="form.depositoryBankAccountUSD"
                            data-cy="form-depository-bank-account-usd"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex class="text-right stepper-2-btns">
                      <v-btn class="mr-2" @click="back(e1--)" color="warning">Back</v-btn>
                      <v-btn color="primary" @click="next(e1++)">Continue</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>

                <v-stepper-content :step="dynamicStepCDF">
                  <v-form ref="formEntryFive">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Principal Contact Person"
                            clearable
                            hide-details="auto"
                            v-model="form.principalContactPerson"
                            data-cy="form-principal-contact-person"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Primary Contact Email"
                            clearable
                            hide-details="auto"
                            v-model="form.primaryContactEmail"
                            :rules="[rules.properEmail]"
                            data-cy="form-primary-contact-email"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Primary Contact Number"
                            clearable
                            hide-details="auto"
                            v-model="form.primaryContactNumber"
                            data-cy="form-primary-contact-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Alternate Contact Person"
                            clearable
                            hide-details="auto"
                            v-model="form.alternateContactPerson"
                            data-cy="form-alternate-contact-person"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Secondary Contact Email"
                            clearable
                            hide-details="auto"
                            v-model="form.secondaryContactEmail"
                            :rules="[rules.properEmail]"
                            data-cy="form-secondary-contact-email"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Secondary Contact Number"
                            clearable
                            hide-details="auto"
                            v-model="form.secondaryContactNumber"
                            data-cy="form-secondary-contact-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Branch Name"
                            clearable
                            hide-details="auto"
                            v-model="form.branchName"
                            data-cy="form-name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Branch Contact Person"
                            clearable
                            hide-details="auto"
                            v-model="form.branchContactPerson"
                            data-cy="form-branch-contact-person"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Branch Email Address"
                            clearable
                            hide-details="auto"
                            v-model="form.branchEmailAddress"
                            data-cy="form-branch-email-address"
                            :rules="[rules.properEmail]"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Branch Contact Number"
                            clearable
                            hide-details="auto"
                            v-model="form.branchContactNumber"
                            data-cy="form-branch-contact-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Telephone Number"
                            clearable
                            hide-details="auto"
                            v-model="form.telephoneNumber"
                            data-cy="form-telephone-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Mobile Number*"
                            clearable
                            hide-details="auto"
                            v-model="form.mobileNumber"
                            :rules="[rules.requiredField, rules.mobileNumber]"
                            data-cy="form-mobile-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="FAX Number"
                            clearable
                            hide-details="auto"
                            v-model="form.faxNumber"
                            data-cy="form-fax-number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Website"
                            clearable
                            hide-details="auto"
                            v-model="form.website"
                            data-cy="form-website"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8>
                          <v-text-field
                            label="Email*"
                            clearable
                            hide-details="auto"
                            v-model="form.email"
                            :rules="[rules.properEmail,rules.requiredField]"
                            data-cy="form-email"
                          ></v-text-field>
                        </v-flex>
                        <!-- <v-flex xs12 sm8>
                          <v-text-field
                            label="Distribution Of Fees"
                            clearable
                            hide-details="auto"
                            v-model="form.distributionOfFees"
                            data-cy="form-distribution-of-fees"
                          ></v-text-field>
                        </v-flex> -->
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Service Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.serviceFee"
                            data-cy="form-service-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Fixed Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.fixedFee"
                            data-cy="form-fixed-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Auth Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.authFee"
                            data-cy="form-auth-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Chargeback Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.chargebackFee"
                            data-cy="form-charge-back-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Monthly Access Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.monthlyAccessFee"
                            data-cy="form-monthly-access-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Refund Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.refundFee"
                            data-cy="form-refund-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Retrieval Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.retrievalFee"
                            data-cy="form-retrieval-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Voice Auth Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.voiceAuthFee"
                            data-cy="form-voice-auth-fee"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm8 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_MERCHANT_DETAILS')">
                          <v-text-field
                            label="Settlement Fee"
                            @keypress="onlyForCurrency"
                            v-model="form.settlementFee"
                            data-cy="form-settlement-fee"
                          ></v-text-field>
                        </v-flex>

                      </v-layout>
                    </v-container>
                    <v-flex class="text-right">
                        <v-btn class="mr-2" @click="back(e1--)" color="warning">Back</v-btn>
                        <v-btn color="primary" @click="save">Save</v-btn>
                    </v-flex>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card-text>
        <v-card-actions class="ma-5">
          <small>*indicates required field</small>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
          <!-- <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn> -->
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-layout>
</template>

<script>
import API from "@/api/API.js";
import {
    mapGetters
} from 'vuex';

export default {
  props: {
    isShow: Boolean,
    isNotPaymentAgent: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    countries: Array,
    merchantCategoryCodes: Array,
    paymentAgents: Array
  },
  computed: {


    // set map getters 
    ...mapGetters([
            'attributes',
            'merchant',
            'authorities'
        ]),

      dynamicStepMDR() {
        return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 2 : -1
      },
      dynamicStepOD() {
        return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 3 : 2
      },
      dynamicSteDBD() {
        return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 4 : 3
      },
      dynamicStepCDF() {
        return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 5 : 4 
      },
    
    populatedForm() {
      return {
        id: this.form.id,
        name: this.form.name,
        legalName: this.form.legalName,
        legalForm: this.form.legalForm,
        merchantAddress: this.form.merchantAddress,
        tradeName: this.form.tradeName,
        companyName: this.form.companyName,
        clientNumber: this.form.clientNumber,
        serviceFee: this.form.serviceFee,
        fixedFee: this.form.fixedFee,
        distributionOfFees: this.form.distributionOfFees,
        fixedDiscountRate: this.form.fixedDiscountRate,
        discountRateDebit: this.form.discountRateDebit,
        fixedDiscountRateDebit: this.form.fixedDiscountRateDebit,
        discountRate: this.form.discountRate,
        primaryContactEmail: this.form.primaryContactEmail,
        secondaryContactEmail: this.form.secondaryContactEmail,
        primaryContactNumber: this.form.primaryContactNumber,
        secondaryContactNumber: this.form.secondaryContactNumber,
        branchName: this.form.branchName,
        branchContactPerson: this.form.branchContactPerson,
        branchContactNumber: this.form.branchContactNumber,
        branchEmailAddress: this.form.branchEmailAddress,
        tinNumber: this.form.tinNumber,
        postalCode: this.form.postalCode,
        city: this.form.city,
        state: this.form.state,
        province: this.form.province,
        country: this.form.country,
        comments: this.form.comments,
        merchantCategoryCode: this.form.merchantCategoryCode,
        enabled: this.form.enabled,
        withholdingTaxRate: this.form.withholdingTaxRate,
        minimumAmount: this.form.minimumAmount,
        maximumAmount: this.form.maximumAmount,
        averageAmount: this.form.averageAmount,
        holdOutRate: this.form.holdOutRate,
        passDiscountRate: this.form.passDiscountRate,
        passFlatFee: this.form.passFlatFee,
        bancnetFlatFee: this.form.bancnetFlatFee,
        serviceFeeLabel: this.form.serviceFeeLabel,
        purchaseIdentifierFormat: this.form.purchaseIdentifierFormat,
        isAsymmetricKeyEnabled: this.form.isAsymmetricKeyEnabled,
        depositoryBankName: this.form.depositoryBankName,
        depositoryBankAccountName: this.form.depositoryBankAccountName,
        depositoryBankAccount: this.form.depositoryBankAccount,
        depositoryBankNameUSD: this.form.depositoryBankNameUSD,
        depositoryBankAccountNameUSD: this.form.depositoryBankAccountNameUSD,
        depositoryBankAccountUSD: this.form.depositoryBankAccountUSD,
        principalContactPerson: this.form.principalContactPerson,
        alternateContactPerson: this.form.alternateContactPerson,
        telephoneNumber: this.form.telephoneNumber,
        mobileNumber: this.form.mobileNumber,
        faxNumber: this.form.faxNumber,
        website: this.form.website,
        email: this.form.email,
        authFee: this.form.authFee,
        chargebackFee: this.form.chargebackFee,
        monthlyAccessFee: this.form.monthlyAccessFee,
        refundFee: this.form.refundFee,
        retrievalFee: this.form.retrievalFee,
        voiceAuthFee: this.form.voiceAuthFee,
        settlementFee: this.form.settlementFee,
        formType: this.formType,
        merchantId: this.form.merchantId,
        merchantCode: this.form.merchantCode,
        expireAfter: this.form.expireAfter,
        validatePayconnect: this.form.validatePayconnect,
        stip: this.form.stip,
        amountEditable: this.form.amountEditable,
        merchantLogoUrl: this.form.merchantLogoUrl,
        isMultipleBiller: this.form.isMultipleBiller,
        allowWalletPayment: this.form.allowWalletPayment,
        isMobileNumberRequired: this.form.isMobileNumberRequired,
        hasPriceAdjustment: this.form.hasPriceAdjustment,
        endpointUrl: this.form.endpointUrl,
        connectTimeout: this.form.connectTimeout,
        readTimeout: this.form.readTimeout,
        randomizeRefNum: this.form.randomizeRefNum,
        referenceReusable: this.form.referenceReusable,
        paymentAgent: this.form.paymentAgent,
        accountCode: this.form.accountCode,
        branchCode: this.form.branchCode,
        accountUsername: this.form.accountUsername,
        accountUsercode: this.form.accountUsercode,
        accountPassword: this.form.accountPassword
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        if(this.formType === "UPDATE") {
          this.field1 = this.selectedItem.distributionOfFees ?? 0
        } else {
          this.field1 = 0
        }
        this.dialog = value;
      },
    },
    field1(newValue) {
      if (newValue !== '') {
        this.field2 = 100 - parseInt(newValue, 10);
         this.form.distributionOfFees = newValue.toString();
      } else {
        this.field2 = '0';
      }
      // const numValue = parseFloat(newValue);
      // if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
      //   this.field2 = (100 - numValue).toFixed(2);
      //   this.form.distributionOfFees = this.field2.toString;
      //   }
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    paymentAgents: {
      handler(value) {
          this.paymentAgentList = value
      }
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    countries: {
      handler(value) {
        this.countryList = value ? value : [];
      },
    },
    merchantCategoryCodes: {
      handler(value) {
        this.merchantCategoryCodeList = value ? value : [];
      },
    },
    bancnetRadioGroupVal() {
        if (this.bancnetRadioGroupVal === 'percentageBancnetVal') {
          this.form.fixedDiscountRateDebit = '0'
        } else if (this.bancnetRadioGroupVal === 'fixedFeeBancnetVal') {
          this.form.discountRateDebit = '0'
        }
    },
    visaRadioGroupVal() {
      if (this.visaRadioGroupVal === 'percentageVisaVal') {
        this.form.fixedDiscountRate = '0'
      } else if (this.visaRadioGroupVal === 'fixedFeeVisaVal') {
        this.form.discountRate = '0'
      }
    },
    // field2(newValue) {
    //   const numValue = parseFloat(newValue);
    //   if (!isNaN(numValue) && numValue >= 0 && numValue <= 100) {
    //     this.field1 = (100 - numValue).toFixed(2);
    //   }
    // },
  },
  data() {
    return {
      bancnetRadioGroupVal: 'percentageBancnetVal',
      visaRadioGroupVal: 'percentageVisaVal',
      loading: false,
      field1: this.selectedItem.distributionOfFees ?? 0,
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      countryList: this.countries ?? [],
      paymentAgentList: this.paymentAgents ?? [],
      merchantCategoryCodeList: this.merchantCategoryCodes ?? [],
      form: {
        country: {},
        merchantCategoryCode: {},
        paymentAgent: {}
      },
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) =>
          (!!v) || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
        maxLength: (v) => (v && v.length <= 4) || "Max 4 characters",
        positiveIntegers: (v) =>
          (!isNaN(v) && /^[0-9]*$/.test(v)) || "Must be positive integers only",
        merchantCodeFormat: (v) => !v || /^[a-zA-Z0-9-_\.]+$/.test(v) || "Must be alphanumeric, only accepts dash(-), underscore(_) and period(.).",
        mobileNumber: v => !v || /^(09)\d{9}$/.test(v) || 'Mobile number must be valid. Ex. starts with (09) followed by xxxxxxxxx, where x = numeric character only', 
        ///^(09|\+639)\d{9}$/ <- if needed full philippine mobile number 
      },
      amountRule: [
        (v) => !!v || 'Amount is required',
        (v) => /^\d{1,2}(\.\d{1,2})?$/.test(v) || 'Invalid amount format',
        (v) => v >= 0 && v <= 100 || 'Amount must be between 0.00 and 100.00',
      ],
      e1: 1,
      isFormComplete: false,
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {

    //get roles attributes
    hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
    },

    // validateNumber() {
    //   if (this.percentageValue > 100) {
    //     this.percentageValue = 100;
    //   }
    // },

    // preventExceedLimit(event) {
    //   const inputValue = parseInt(this.percentageValue.toString() + event.key);
    //   if (inputValue >= 100 || this.percentageValue.toString().length === 3) {
    //     event.preventDefault();
    //   }
    // },

    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    next(item) {
      console.log("NUMBER E1:" + item)
      console.log("VALIDATE FORM: " + this.$refs.formEntryOne.validate())
      console.log("VALIDATE FORM: " + this.$refs.formEntryTwo.validate())
      if(this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')) {
        if (item == 1 && this.$refs.formEntryOne.validate()) {
          this.e1 = 2;
        } else if (item == 2 && this.$refs.formEntryTwo.validate()) {
          this.e1 = 3;
        } else if (item == 3 && this.$refs.formEntryThree.validate()) {
          this.e1 = 4;
        } else if (item == 4 && this.$refs.formEntryFour.validate()) {
          this.e1 = 5;
        }
      } else {
        if (item == 1 && this.$refs.formEntryOne.validate()) {
          this.e1 = 2;
        } else if (item == 2 && this.$refs.formEntryThree.validate()) {
          this.e1 = 3;
        } else if (item == 3 && this.$refs.formEntryFour.validate()) {
          this.e1 = 4;
        }
      }
    },
    back(item) {
      if(item === 0) return 1
      if(this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS')) {
          if (item == 5) {
            this.e1 = 4;
          } else if (item == 4) {
            this.e1 = 3;
          } else if (item == 3) {
            this.e1 = 2;
          } else if (item == 2) {
            this.e1 = 1;
          }
      } else {
          if (item == 4) {
            this.e1 = 3;
          } else if (item == 3) {
            this.e1 = 2;
          } else if (item == 2) {
            this.e1 = 1;
          }
      }
    },
    save() {
      if (this.$refs.formEntryFive.validate() && this.$refs.formEntry.validate()) {
        this.isFormComplete = true;
        this.$emit("save-merchant", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      this.e1 = 1;
      if (this.formType === "CREATED") {
        this.form = {
          name: undefined,
          legalName: undefined,
          legalForm: undefined,
          merchantAddress: undefined,
          tradeName: undefined,
          companyName: undefined,
          clientNumber: undefined,
          serviceFee: "0",
          fixedFee: "0",

          distributionOfFees: 0, // distribution of fee ( merchant )
          fixedDiscountRate: "0", // visa fixed fee
          discountRateDebit: "0", // bancnet percentage
          fixedDiscountRateDebit: "0", // bancnet fixed fee
          discountRate: "0", // visa percentage

          primaryContactEmail: undefined,
          secondaryContactEmail: undefined,
          primaryContactNumber: undefined,
          secondaryContactNumber: undefined,
          branchName: undefined,
          branchContactPerson: undefined,
          branchContactNumber: undefined,
          branchEmailAddress: undefined,
          tinNumber: undefined,
          postalCode: undefined,
          city: undefined,
          state: undefined,
          province: undefined,
          country: undefined,
          comments: undefined,
          paymentAgent: undefined,
          merchantCategoryCode: undefined,
          enabled: false,
          withholdingTaxRate: "0",
          minimumAmount: "0",
          maximumAmount: "0",
          averageAmount: "0",
          holdOutRate: "0",
          passDiscountRate: false,
          passFlatFee: false,
          bancnetFlatFee: "0",
          serviceFeeLabel: undefined,
          purchaseIdentifierFormat: undefined,
          isAsymmetricKeyEnabled: false,
          depositoryBankName: undefined,
          depositoryBankAccountName: undefined,
          depositoryBankAccount: undefined,
          depositoryBankNameUSD: undefined,
          depositoryBankAccountNameUSD: undefined,
          depositoryBankAccountUSD: undefined,
          principalContactPerson: undefined,
          alternateContactPerson: undefined,
          telephoneNumber: undefined,
          mobileNumber: undefined,
          faxNumber: undefined,
          website: undefined,
          email: undefined,
          authFee: "0",
          chargebackFee: "0",
          monthlyAccessFee: "0",
          refundFee: "0",
          retrievalFee: "0",
          voiceAuthFee: "0",
          settlementFee: "0",
          merchantId: undefined,
          merchantCode: undefined,
          expireAfter: "2880",
          validatePayconnect: false,
          stip: false,
          amountEditable: false,
          merchantLogoUrl: undefined,
          isMultipleBiller: false,
          allowWalletPayment: false,
          isMobileNumberRequired: false,
          hasPriceAdjustment: false,
          endpointUrl: undefined,
          connectTimeout: "3000",
          readTimeout: "10000",
          randomizeRefNum: false,
          referenceReusable: false,
          accountCode: undefined,
          branchCode: undefined,
          accountUsername: undefined,
          accountUsercode: undefined,
          accountPassword: undefined
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        this.form = {
          id: this.selectedItem.id,
          name: this.selectedItem.name,
          legalName: this.selectedItem.legalName,
          legalForm: this.selectedItem.legalForm,
          merchantAddress: this.selectedItem.merchantAddress,
          tradeName: this.selectedItem.tradeName,
          companyName: this.selectedItem.companyName,
          clientNumber: this.selectedItem.clientNumber,
          serviceFee: this.selectedItem.serviceFee,
          fixedFee: this.selectedItem.fixedFee,

          distributionOfFees: this.selectedItem.distributionOfFees,
          fixedDiscountRate: this.selectedItem.fixedDiscountRate,
          discountRateDebit: this.selectedItem.discountRateDebit,
          fixedDiscountRateDebit: this.selectedItem.fixedDiscountRateDebit,
          discountRate: this.selectedItem.discountRate,

          primaryContactEmail: this.selectedItem.primaryContactEmail,
          secondaryContactEmail: this.selectedItem.secondaryContactEmail,
          primaryContactNumber: this.selectedItem.primaryContactNumber,
          secondaryContactNumber: this.selectedItem.secondaryContactNumber,
          branchName: this.selectedItem.branchName,
          branchContactPerson: this.selectedItem.branchContactPerson,
          branchContactNumber: this.selectedItem.branchContactNumber,
          branchEmailAddress: this.selectedItem.branchEmailAddress,
          tinNumber: this.selectedItem.tinNumber,
          postalCode: this.selectedItem.postalCode,
          city: this.selectedItem.city,
          state: this.selectedItem.state,
          province: this.selectedItem.province,
          country: this.selectedItem.country ?? {},
          comments: this.selectedItem.comments,
          paymentAgent: this.selectedItem.paymentAgent ?? {},
          merchantCategoryCode: this.selectedItem.merchantCategoryCode ?? {},
          enabled: this.selectedItem.enabled,
          withholdingTaxRate: this.selectedItem.withholdingTaxRate,
          minimumAmount: this.selectedItem.minimumAmount,
          maximumAmount: this.selectedItem.maximumAmount,
          averageAmount: this.selectedItem.averageAmount,
          holdOutRate: this.selectedItem.holdOutRate,
          passDiscountRate: this.selectedItem.passDiscountRate,
          passFlatFee: this.selectedItem.passFlatFee,
          bancnetFlatFee: this.selectedItem.bancnetFlatFee,
          serviceFeeLabel: this.selectedItem.serviceFeeLabel,
          purchaseIdentifierFormat: this.selectedItem.purchaseIdentifierFormat,
          isAsymmetricKeyEnabled: this.selectedItem.isAsymmetricKeyEnabled,
          depositoryBankName: this.selectedItem.depositoryBankName,
          depositoryBankAccountName: this.selectedItem.depositoryBankAccountName,
          depositoryBankAccount: this.selectedItem.depositoryBankAccount,
          depositoryBankNameUSD: this.selectedItem.depositoryBankNameUSD,
          depositoryBankAccountNameUSD: this.selectedItem.depositoryBankAccountNameUSD,
          depositoryBankAccountUSD: this.selectedItem.depositoryBankAccountUSD,
          principalContactPerson: this.selectedItem.principalContactPerson,
          alternateContactPerson: this.selectedItem.alternateContactPerson,
          telephoneNumber: this.selectedItem.telephoneNumber,
          mobileNumber: this.selectedItem.mobileNumber,
          faxNumber: this.selectedItem.faxNumber,
          website: this.selectedItem.website,
          email: this.selectedItem.email,
          authFee: this.selectedItem.authFee,
          chargebackFee: this.selectedItem.chargebackFee,
          monthlyAccessFee: this.selectedItem.monthlyAccessFee,
          refundFee: this.selectedItem.refundFee,
          retrievalFee: this.selectedItem.retrievalFee,
          voiceAuthFee: this.selectedItem.voiceAuthFee,
          settlementFee: this.selectedItem.settlementFee,
          merchantId: this.selectedItem.merchantId,
          merchantCode: this.selectedItem.merchantCode,
          expireAfter: this.selectedItem.expireAfter,
          validatePayconnect: this.selectedItem.validatePayconnect,
          stip: this.selectedItem.stip,
          amountEditable: this.selectedItem.amountEditable,
          merchantLogoUrl: this.selectedItem.merchantLogoUrl,
          isMultipleBiller: this.selectedItem.isMultipleBiller,
          allowWalletPayment: this.selectedItem.allowWalletPayment,
          isMobileNumberRequired: this.selectedItem.isMobileNumberRequired,
          hasPriceAdjustment: this.selectedItem.hasPriceAdjustment,
          endpointUrl: this.selectedItem.endpointUrl,
          connectTimeout: this.selectedItem.connectTimeout,
          readTimeout: this.selectedItem.readTimeout,
          randomizeRefNum: this.selectedItem.randomizeRefNum,
          referenceReusable: this.selectedItem.referenceReusable,
          accountCode: this.selectedItem.accountCode,
          branchCode: this.selectedItem.branchCode,
          accountUsername: this.selectedItem.accountUsername,
          accountUsercode: this.selectedItem.accountUsercode,
          accountPassword: this.selectedItem.accountPassword
        };
      }
    },
    onlyForCurrency ($event) {
        // console.log($event.keyCode); //keyCodes value
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

        // only allow number and one dot
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
            $event.preventDefault();
        }

        // restrict to 2 decimal places
        if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
            $event.preventDefault();
        }
    }
  },
};
</script>

<style scoped>
.stepper-1-btns {
  margin-top: 30px !important;
}

.stepper-2-btns {
  margin-top: 74px !important;
}

</style>
